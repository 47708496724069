<template>
  <div class="home">
    <img alt="Vue logo" height="200px" style="margin: 100px;" src="@/assets/cosmos-logo.png"> <!-- @ işaretiyle assets klasörünün doğru yolu belirtildi -->
    <div class="welcome">
      <h1>Cosmos IT - HomeEC Project</h1>
      <p>Hello World</p>
      <h1>{{ testData }}</h1>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HomeView',

  data() {
    const endpoint =  'test';

    return {
      testData: '',
      endpoint
    };
  },

  mounted() {
    this.fetchTestData();
  },

  methods: {
    fetchTestData() {

      axios.get(this.endpoint)
        .then(response => {
          this.testData = response.data.datetime;
        })
        .catch(error => {
          console.error('Error : ', error);
        });
    }
  }
}
</script>
